<template>
  <div class="home">
    <child-page-head :title="title" />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-inspection>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <left-drawer-content id="inspection-pie-quality" title="质量问题" type="QUALITY" pie-title="质量问题分布" />
      </div>
      <!-- 左侧内容-下 -->
      <div slot="leftDrawerBottomContent">
        <left-drawer-content id="inspection-pie-safety" title="安全问题" type="SAFETY" pie-title="安全问题分布" />
      </div>
      <!-- 中间部分-上 -->
      <div slot="MiddleDrawerTopContent">
        <middle-drawer-content id="inspection-line-quality" title="质量问题近7天增长趋势" type="QUALITY" />
      </div>
      <!-- 中间部分-下 -->
      <div slot="MiddleDrawerBottomContent">
        <middle-drawer-content id="inspection-line-safety" title="安全问题近7天增长趋势" type="SAFETY" />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <right-drawer-content title="质量问题整改清单" type="QUALITY" />
      </div>
      <!-- 右侧内容-下 -->
      <div slot="rightDrawerBottomContent">
        <right-drawer-content title="安全问题整改清单" type="SAFETY" />
      </div>
    </drawer-inspection>
  </div>
</template>
<script>
import childPageHead from '@/components/child-page-head'
import drawerInspection from '@/components/drawer-inspection'
import leftDrawerContent from './components/left-drawer-content.vue'
import middleDrawerContent from './components/middle-drawer-content.vue'
import RightDrawerContent from './components/right-drawer-content.vue'
export default {
  name: 'InspectionHome',
  components: {
    drawerInspection,
    childPageHead,
    leftDrawerContent,
    middleDrawerContent,
    RightDrawerContent
  },
  data() {
    return {
      title: '人工巡检'
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
