<template>
  <div class="problem-list-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(config)" class="problem-list-context">
      <dv-scroll-board :config="config" class="dv-scroll-board" />
    </div>
    <div v-else class="stat-charts-context empty">暂无{{ title }}</div>
  </div>
</template>
<script>
import { getLatestList } from '@/api/inspection'
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'PatrolProblemList',
  props: {
    title: {
      type: String,
      default: '问题列表'
    },
    type: {
      type: String,
      default: 'QUALITY'
    }
  },
  data() {
    return {
      top: 50,
      config: null,
      header: [
        '责任单位',
        '责任班组',
        '责任人',
        '上报日期',
        '状态',
        '整改日期',
        '截止日期'
      ],
      columnWidth: [171.25, 160.75, 102, 102, 83.5, 102, 102],
      aligns: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center',
        'center'
      ]
    }
  },
  mounted() {
    this.removePatrolProblemListList()
  },
  methods: {
    removePatrolProblemListList() {
      getLatestList(this.top, this.type).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(item.corpName)
            _itemData.push(item.dutyTeamName)
            _itemData.push(item.solveName)
            _itemData.push(parseTime(item.reportDate, '{y}-{m}-{d}'))
            _itemData.push(item.stateStr)
            _itemData.push(parseTime(item.solveDate, '{y}-{m}-{d}'))
            _itemData.push(parseTime(item.asOfDate, '{y}-{m}-{d}'))
            _data.push(_itemData)
            _data.push(_itemData)
            _data.push(_itemData)
            _data.push(_itemData)
          })
          this.config = {
            header: this.header,
            columnWidth: this.columnWidth,
            align: this.aligns,
            data: _data,
            rowNum: 6,
            headerHeight: 50,
            headerBGC: 'rgba(0,255,255,.3)',
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.problem-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .problem-list-context {
    margin-top: 20px;
    .dv-scroll-board {
      height: 396px;
    }
  }
}
</style>
