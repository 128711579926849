<template>
  <div class="inspection-stat-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div class="stat-context">
      <div class="l">
        <span>{{ title }}</span>
        <div class="xcqk" style="margin-top: 35%">
          <div>
            <dv-digital-flop :config="totalConfig" class="dv-digital-flop" />
            总问题
          </div>
          <div>
            <dv-digital-flop :config="checkedConfig" class="dv-digital-flop" />
            已整改
          </div>
          <div>
            <dv-digital-flop
              :config="unCheckedConfig"
              class="dv-digital-flop"
            />
            待整改
          </div>
        </div>
        <div class="xcqk">
          <div>
            <dv-digital-flop :config="overdueConfig" class="dv-digital-flop" />
            整改逾期
          </div>
          <div>
            <dv-digital-flop
              :config="checkedRateConfig"
              class="dv-digital-flop"
            />
            整改率
          </div>
        </div>
      </div>
      <div class="r">
        <pie-chart
          :id="id"
          :val-options="stateOptions"
          :title="pieTitle"
          unit="个"
          serie-name="数量"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getTotalStat } from '@/api/inspection'
import pieChart from '@/components/chart/pieChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'InspectionStatView',
  components: { pieChart },
  props: {
    id: {
      type: String,
      default: 'inspection-pie'
    },
    title: {
      type: String,
      default: '统计图'
    },
    pieTitle: {
      type: String,
      default: '统计图'
    },
    type: {
      type: String,
      default: 'QUALITY'
    }
  },
  data() {
    return {
      totalConfig: null,
      checkedConfig: null,
      unCheckedConfig: null,
      overdueConfig: null,
      stateOptions: null,
      checkedRateConfig: null
    }
  },
  mounted() {
    this.removeLoadTotalStat()
  },
  methods: {
    removeLoadTotalStat() {
      this.stateOptions = []
      getTotalStat(this.type).then((res) => {
        const { data } = res
        const _total = data.checked + data.unChecked
        this.totalConfig = {
          number: [_total],
          style: {
            fill: '#7ecef4'
          }
        }
        this.checkedConfig = {
          number: [data.checked],
          style: {
            fill: '#7c9dfe'
          }
        }
        this.unCheckedConfig = {
          number: [data.unChecked],
          style: {
            fill: '#fd5d05'
          }
        }
        this.overdueConfig = {
          number: [data.overdue],
          style: {
            fill: '#875dc1'
          }
        }
        const _rate =
          data.unChecked <= 0 || _total <= 0
            ? 100
            : Math.ceil(((data.checked * 1.0) / _total) * 100)

        this.checkedRateConfig = {
          number: [_rate],
          content: '{nt}%',
          style: {
            fill: '#fad400'
          }
        }
        if (hasVal(data.state)) {
          for (const prop in data.state) {
            this.stateOptions.push({
              text: prop,
              value: data.state[prop]
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.inspection-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    display: flex;
    margin-top: 50px;
    .l {
      flex: 1;
      text-align: center;
      border-right: 1px dashed #0e77ca9e;
      > span {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #ffffff;
        line-height: 36px;
        display: inline-block;
      }
      .xcqk {
        margin: 20px 10px 0px 10px;
        box-sizing: border-box;
        display: flex;
        .dv-digital-flop {
          height: 30px;
        }
        > div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          flex: 1;
          text-align: center;
        }
      }
    }
    .r {
      flex: 1;
      text-align: center;
      span {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
}
</style>
