<template>
  <div class="personnel-stat-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(trendTrendStat)" class="stat-charts-context">
      <line-chart
        :id="id"
        :val-options="trendTrendStat"
        :height="380"
        title=""
        unit="个"
        :y-axis-title="yAxisTitle"
        serie-name="数量"
      />
    </div>
    <div v-else class="stat-charts-context empty">
      暂无{{ title }}
    </div>
  </div>
</template>
<script>
import { getTrendStatLine } from '@/api/inspection'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'TrendTrendStat',
  components: { lineChart },
  props: {
    id: {
      type: String,
      default: 'inspection-line'
    },
    title: {
      type: String,
      default: '统计图'
    },
    yAxisTitle: {
      type: String,
      default: '问题趋势(个/天)'
    },
    type: {
      type: String,
      default: 'QUALITY'
    }
  },
  data() {
    return {
      trendTrendStat: []
    }
  },
  mounted() {
    this.removeTrendStatLine()
  },
  methods: {
    removeTrendStatLine() {
      this.trendTrendStat = []
      getTrendStatLine(this.type).then((res) => {
        const { data } = res
        if (hasVal(data.subbmitted)) {
          this.trendTrendStat.push({
            name: '新增问题',
            data: data.subbmitted,
            isAllData: false
          })
        }
        if (hasVal(data.checked)) {
          this.trendTrendStat.push({
            name: '已整改',
            data: data.checked,
            isAllData: false
          })
        }
        if (hasVal(data.unChecked)) {
          this.trendTrendStat.push({
            name: '未整改',
            data: data.unChecked,
            isAllData: false
          })
        }
        if (hasVal(data.overdueAndChecked)) {
          this.trendTrendStat.push({
            name: '整改逾期',
            data: data.overdueAndChecked,
            isAllData: false
          })
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
