import request from '@/utils/request'

/** *
 * 获取问题巡检总统计
 */
export function getTotalStat(type) {
  return request({
    url: '/PatrolProblem/GetTotalStat',
    method: 'GET',
    params: {
      type: type
    }
  })
}

/** *
 * 获取问题巡检最新数据
 */
export function getLatestList(top, type) {
  return request({
    url: '/PatrolProblem/GetLatestList',
    method: 'GET',
    params: {
      limit: top,
      type: type
    }
  })
}

/** *
 * 获取项目下的问题完成数、未完成数和逾期完成数的统计数据
 */
export function getTrendStatLine(type) {
  return request({
    url: '/PatrolProblemStats/Project/TrendStatLine',
    method: 'GET',
    params: {
      type: type
    }
  })
}
